  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Regular-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-BlackItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 900;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Bold-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: bold;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: bold;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 300;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-ExtraBold-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 800;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-ExtraBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 800;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Black-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 900;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 200;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Book-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Thin-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 100;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 800;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Light-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 300;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype');
    font-style: normal;
    font-weight: 200;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-ThinItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 100;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 800;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 600;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Book.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: 'Brother 1816';
    src: url('./fonts/brother-1816/Brother-1816-Medium-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('./fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
  }
